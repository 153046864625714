<template>
  <div class="allmsg">
    <navigation :msg="msg"></navigation>
    <pre>

<h1>隐私政策</h1>
  本《隐私政策》（以下简称“本政策”）旨在说明上海猎后征信服务有限公司（以下简称“萝卜猎手”、“技术开发方”或“我们”）保护用户信息相关的方式与政策，并为您提供访问我们所维护的关于您的个人信息的渠道。
  保护用户个人信息是我们的一项基本原则，我们将会采取合理的措施保护您的个人信息。除法律法规规定或您授权的情形外，未经您的许可我们不会向任何第三方公开、透露用户个人信息。我们郑重承诺，我们承认、尊重并保护您向我们提供的所有隐私。
  您的使用行为将视为接受本政策，同意我们按照本政策之约定采集、查询、验证、处理、在法定期限内以合法方式储存您的信息。如果本政策的一些条款不完整或已经过时，或者是考虑到您的利益需要修改本政策，我们会根据需要对本政策进行修改。若我们对本政策做出重大变更，我们将在官网上发布最新修订的政策。新的政策将在发布后即时生效。

<h2>1、个人信息的收集</h2>
  基于本服务的职业背景调查的应用场景以及我们作为技术服务方的属性，您在使用我们的服务时，需确认您已授权我们及其相关合作伙伴合法对您进行背景调查。当您使用（包括通过您连接的产品使用）我们服务时，我们将基于本政策之目的和方法收集您的个人信息。
  1.1我们进行背景调查时您直接填写并授权的信息：
    a.基于您应聘企业认证是您本人的需要而由您提供的<strong>身份信息（包括姓名、身份证号、手机号）；</strong>
    b. 基于您应聘企业认证您教育经历的需要而由您提供的<strong>教育信息（包括学历证书信息及照片、学位证书信息及照片）；</strong>
    c. 基于您应聘企业认证您职业由需要而由您提供的<strong>职业证书信息（包括专业资质证书信息及照片、职业资格证书及照片、驾驶证信息及照片）；</strong>
    d. 基于您应聘企业认证您工作经历的需要而由您提供的<strong>工作信息（包括公司名称、所在城市、职位名称、薪资范畴、hr姓名及联系方式、直接上级姓名及联系方式、其他证明人姓名及联系方式）；</strong>
  1.2 我们进行背景调查时间接收集的信息：
    基于您应聘企业背景调查的需要而在公开、官方、权威渠道查得的<strong>法院民事诉讼及失信记录、负面社会安全记录、金融行业违规记录、个人工商信息记录、金融风险记录。</strong>
  1.3 我们进行背景调查时您提交给应聘企业并授权的简历信息：
    基于您应聘企业选择“简历比对”服务所需要的<strong>教育背景信息</strong>：学历证书、学位证书；<strong>工作履历信息</strong>：工作单位名称、岗位、任职时间、HR、直接上级或其他联系人电话。
    <strong>为了保证您授权的合法性，</strong>我们将接入<strong>第三方人脸识别技术、电子签名技术</strong>确认该授权由您本人做出，届时将您的身份信息（身份证号码、姓名）、活体照片传至电子签名技术方完成您的电子授权。
  1.4 设备权限调用
    我们在提供服务过程中，<strong>会调用您的一些设备权限</strong>，您可以在设备的设置功能中<strong>选择关闭部分或全部权限，从而拒绝</strong>我们收集与使用相应的个人信息。在不同设备中，权限显示方式及关闭方式有所不同，请您参照设备或系统开发说明与指引。以下是我们调用权限对应的业务功能、调用权限的目的以及调用权限前向您询问的情况：
    <table>
        <tr>
          <td>设备权限</td>
          <td>业务功能</td>
          <td>调用目的</td>
          <td>是否询问</td>
          <td>是否关闭</td>
        </tr>
        <tr>
          <td>相机</td>
          <td>提供教育、职业信息，具体为学历、学位证书、职业证书</td>
          <td>实现拍照上传功能</td>
          <td>调用相关功能询问</td>
          <td>建议保留</td>
        </tr>
        <tr>
          <td>照片</td>
          <td>提供教育、职业信息，具体为学历、学位证书、职业证书</td>
          <td>浏览图片，选择信息图片上传</td>
          <td>调用相关功能询问</td>
          <td>建议保留</td>
        </tr>
         <tr>
          <td>存储</td>
          <td>信息存储</td>
          <td>填写信息暂时存储</td>
          <td>“暂时储存”功能问询</td>
          <td>否</td>
        </tr>
    </table>
<h2>2、个人信息的使用</h2>
  2.1 使用
    我们将按照前述声明在服务中使用您的个人信息，<strong>如我们基于本政策未载明其他用途或非特定目的收集或使用您的个人信息，我们会事先征求您的同意。</strong>
    我们将根据您的授权，在为您进行背景调查时使用上述信息。若您撤销授权，我们将停止使用并根据您的要求对您的个人信息进行删除。
    如果我们停止运营“萝卜猎手”产品及服务，我们将及时停止您仅授权于该服务使用的个人信息，并将以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理（匿名化处理后的信息将无法复原且无法识别出个人信息）。
  2.2 去标识化
    在收到您的个人信息后，我们会采取合理措施（包括数据加密、访问控制、恶意代码防范、安全审计等）对您的个人信息进行去标识化处理或匿名化处理，去标识化处理的信息将无法识别主体，同时督促并要求授权合作伙伴进行严格保密。<strong>在不透露您个人信息的前提下，我们有权对匿名化处理后的用户数据进行挖掘、分析和利用（包括商业化利用）。</strong>
  2.3 Cookies的使用
    Cookies是指我们为了辨别用户身份而储存在您本地终端上的数据（<strong>通常被加密</strong>）。
    在您<strong>未拒绝接受cookies的情况下，本网站可能会在用户的计算机上设定或取用cookies，</strong>以便您能登录或使用依赖于cookies的我们的服务或功能。我们使用cookies可为您提供更加周到的个性化服务，包括推广服务。
    您有权选择接受或拒绝接受cookies，您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本服务或功能。
    我们通过cookies所取得的有关信息，将适用本隐私保护政策。

<h2>3. 接入的第三方</h2>
  为了完成背景调查需要，我们的服务中接入了第三方合作方以完成身份验证、人脸识别（例如芝麻人脸、微众人脸等）、电子签名（如e签宝等）。
  3.1共享
    我们会以高度勤勉义务对待您的信息，不会与技术开发方以外的任何公司、组织和个人分享您的个人信息，<strong>但以下情况除外：</strong>
      a.获得您的明确授权或同意；
      b.根据使用法律法规、法律程序的要求或强制性政府要求或司法裁定我们必须提供；
      c.仅为实现本隐私政策中声明的目的，我们的某些服务将由授权的合作伙伴提供，我们将会向合作伙伴等第三方提供您的个人信息。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于其他任何用途，并将遵守本隐私政策保护您的个人信息。
    <strong>我们的授权合作伙伴可能包括以下类型：</strong>
      a.<strong>您的应聘公司</strong>，您同意将相关信息核验结果即《背景信息调查报告》返回给授权主体（一般为您的应聘公司）。但该等信息不构成我们对您的任何明示或暗示的观点，亦不能视为代替合作伙伴进行任何形式的评估、判断或决策。在任何情况下，合作伙伴依据我们提供的服务而对您做出的决定或者采取的行动不会也不应由我们承担责任。
      b.<strong>身份验证类服务提供商</strong>，为完成您应聘企业认证是您本人的需要而向权威渠道方核实身份信息的服务。
      c.<strong>官方画像信息提供商</strong>，为完成您应聘企业及应聘岗位背景调查的需要或基于法律法规、法律程序的要求或强制性政府要求或司法裁定，或金融、征信审查等机构的合法要求，对您的个人信息分享给合作伙伴，用于法院民事诉讼及失信记录查询、金融行业违规记录查询、个人工商信息记录查询、金融风险记录查询。
    在共享信息前，我们会与共享个人信息的第三方签署严格的数据安全和保密协定，要求他， 按照我们的说明、本隐私政策采取相关的保密和安全措施来处理个人信息。
  3.2 披露
    我们可能在以下几种限制情况下披露您的个人信息，包括：
      a.与我们授权的以代表我们执行某些功能或提供某些服务的服务提供商共享信息（仅当我们确知该等服务提供商不会拥有和泄露您的个人信息时）；
      b.回应传票，法院命令或其他法律程序，或建立或行使我们的法律权利或对法律索偿作出辩护；
      c.当我们认为有必要对非法活动、涉嫌欺诈的活动、以及为法律索赔的辩护活动或法律要求的其他活动进行调查、预防、或采取行动时；
      d.需要提供信息以保护或捍卫我们或他人的权利或财产以及公众安全时；
      e.需要就一项公司交易（如剥离、并购、合并或资产出售）共享信息。
  <strong>除此之外，未经您的书面授权，我们不会将您的个人信息提供给任何其他方。</strong>

<h2>4.个人信息的存储</h2>
  4.1保存地域
    您的个人信息存放地域为中华人民共和国国内，如需跨境传输，我们将会单独征得您的同意。
  4.2保存期限
    我们将采取合理措施，避免收集无关的个人信息。我们只会在技术服务实现所需的必要时间内保留您的信息，由于您的信息主要由您已授权的应聘公司查阅与使用，我们将结合法律规定与合作伙伴的合理需要评估最少留存期限，前述最少留存期限届满时，我们将对个人信息销毁、删除或脱敏处理（脱敏处理后的信息将无法复原且无法识别出个人信息）。

<h2>5．个人信息的保护</h2>
  我们已采取了符合惯例的安全措施以保护您提供给我们的个人信息。然而，没有一种传输或存储电子数据的方法是完全安全的，我们无法保证该等信息永远不会以违反本隐私政策的方式被访问、使用或发布。
  在提供服务过程中，我们不会向任何第三方披露该等信息或将该等信息用于任何第三方。我们的任何应用中收集、提交的数据也只会用于该应用的自身用途，而不会为其他任何应用所访问和使用。

<h2>6．个人信息权利保障</h2>
  您在使用我们技术服务过程中，我们已为您提供了必要的信息更正支持，如您想<strong>撤回</strong>对我们的授权的，<strong>可退出或关闭我们的服务。</strong>
  如果您对我们的隐私政策有任何疑问，<strong>您可通过以下方式联系我们：</strong>
  技术开发方名称：上海猎后征信服务有限公司
  联系邮箱：bd@lblsvip.com
  请在联系我们时提供：
    （1）您的姓名（名称）和联系方式；
    （2）您的详细要求或相应的网站链接及意见。

<h2>7.未成年人隐私</h2>
  鉴于我们背景调查服务的应用场景，仅限18岁以上的用户使用，如果您未满18周岁，请您停止使用我们的服务。您应该明确了解，若我们在服务过程中发现或怀疑您未满18周岁的，则我们可以随时终止或中止向您提供服务，直至您向我们提供您已满18周岁的证明，或协助我们获得您的监护人的明示同意。
  对于经父母或监护人同意而收集儿童个人信息的情况，我们只会在受法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。在此情况下，如果我们发现自己在未事先获得父母同意的情况下收集的儿童的个人信息，会设法尽快删除相关数据。
  如果您为未成年人的父母或监护人，当您对您所监护的未成年人的个人信息处理存在疑问时，请通过本文第六条中的联系方式联系我们。

<h2>8.隐私政策的变更</h2>
  我们未来可能会更新本隐私政策。我们会在我们的网站上放置醒目的通知。

        </pre
    >
  </div>
</template>
<script>
export default {
  
  data(){
    return{
      msg:"隐私协议"
    }
  },
}
</script>
<style scoped>
.allmsg{
    background-color: #ffffff;
    overflow: hidden;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 0.5rem;
  font-size: 0.25rem;
  padding: 0.2rem;
}

a {
  color: #40b8b4;
}
table{
  border-collapse: collapse;
}
tr{
  border: 1px solid #000000;
}
td{
  border: 1px solid #000000;
}
</style>
